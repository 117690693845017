import { useEffect } from 'react';
import { FacilityBotLogo } from '../Svg';
import { pricingPlansThumbnail } from '../../utils/images';
import PricingPlan from './PricingPlan';
import FeatureGroup from './FeatureGroup';

import { PLANS, PLAN_BENEFITS } from '../../data/pricing_plans';
import { calcDynamicPlusPrice, handlePlusAccount } from './helpers';

export default function PricingTable({ isAnnual, displayCurrency, plus }) {
	const billedBy = isAnnual ? 'annual' : 'monthly';
	let plansRow_yPos_min = 400;
	let plansRow_yPos_max = 6250;

	const checkValChange = (val) => {
		if (val) {
			const price = calcDynamicPlusPrice(plus.accounts, isAnnual, displayCurrency);
			plus.onPriceChange(price);
		}
	};

	useEffect(() => {
		checkValChange(displayCurrency);
		checkValChange(billedBy);
		checkValChange(plus.accounts);
	}, [displayCurrency, billedBy, plus.accounts]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		let plansRow = document.getElementById('plansRow');
		let planBenefitRows = document.getElementById('planBenefitRows');
		if (plansRow) {
			let headerHeight = document.getElementById('headerFixed').clientHeight;
			let offsets = plansRow.getBoundingClientRect();
			plansRow_yPos_min = offsets.top - headerHeight;
			plansRow_yPos_max = planBenefitRows.getBoundingClientRect().bottom - headerHeight - plansRow_yPos_min;
		}

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	function handleScroll() {
		let plansRow = document.getElementById('plansRow');
		if (window.scrollY > plansRow_yPos_min && window.scrollY <= plansRow_yPos_max) {
			plansRow.classList.add('is-fixed');
		} else {
			plansRow.classList.remove('is-fixed');
		}
	}

	function handlePlusAccounts(val) {
		handlePlusAccount(val, plus, isAnnual, displayCurrency);
	}

	const PricingPlansRow = () => {
		return Object.entries(PLANS).map(([key, { name, currency, maximum_accounts }]) => (
			<PricingPlan
				key={key}
				planKey={key}
				name={name}
				currency={currency}
				displayCurrency={displayCurrency}
				billedBy={billedBy}
				isAnnual={isAnnual}
				plus={plus}
				handlePlusAccounts={handlePlusAccounts}
				maximumAccounts={maximum_accounts}
			/>
		));
	};

	const PlansBenefits = () => {
		return PLAN_BENEFITS.map(({ feature_group_name, link, features }, featGroupIndex) => (
			<FeatureGroup
				key={featGroupIndex}
				groupIdx={featGroupIndex}
				feature_group_name={feature_group_name}
				link={link}
				features={features}
				plus={plus}
			/>
		));
	};

	return (
		<div className="pricing-structure">
			<div className="pricing-table-container">
				<div className="plan-row-wrapper" style={{ height: `450px` }}>
					<div className="plan-row" id="plansRow">
						<PricingTableLogo />
						<PricingPlansRow />
					</div>
				</div>

				<div className="plan-benefit-rows" id="planBenefitRows">
					<PlansBenefits />
				</div>
			</div>
		</div>
	);
}

const PricingTableLogo = () => (
	<div className="plan-column comparison-header">
		<FacilityBotLogo />
		<img
			src={pricingPlansThumbnail}
			alt=""
			style={{ marginTop: '30px', position: 'relative', left: '50%', transform: 'translate(-50%, 0%)' }}
		/>
	</div>
);
