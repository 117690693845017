import { DEFAULT_ACCOUNTS, DEFAULT_PRICE, PER_ACCOUNT_PRICE } from '../../constants/pricing';

export function calcDynamicPlusPrice(accountsCount, isAnnual, chosenCurrency) {
	if (accountsCount === DEFAULT_ACCOUNTS && isAnnual === false && chosenCurrency === 'USD') {
		return DEFAULT_PRICE;
	}

	const additionalAccounts = accountsCount - DEFAULT_ACCOUNTS;
	const pricePerAdditionalAcc = PER_ACCOUNT_PRICE[chosenCurrency][isAnnual ? 'annual' : 'monthly'];

	if (!isAnnual && chosenCurrency === 'USD') return DEFAULT_PRICE + additionalAccounts * pricePerAdditionalAcc;

	return accountsCount * pricePerAdditionalAcc;
}

export function handlePlusAccount(val, plus, isAnnual, displayCurrency) {
	if (val < DEFAULT_ACCOUNTS || isNaN(val)) {
		val = DEFAULT_ACCOUNTS;
	}

	plus.onAccountsChange(val);
	const price = calcDynamicPlusPrice(val, isAnnual, displayCurrency);
	plus.onPriceChange(price);
}
