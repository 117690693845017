import { useState } from 'react';
import dynamic from 'next/dynamic';

import FontAwesomeIcon from './FontAwesomeIcon';
import sysShowcaseVideo from '../public/videos/quick_system_showcase.mp4';
import introVideo from '../public/videos/intro_video_nov2024.mp4';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });
const MAX_MOBILE_WIDTH = 768;

export default function Demo() {
	const [showType, setShowType] = useState('showcase');

	function handlePlayIntro() {
		if (window.innerWidth <= MAX_MOBILE_WIDTH) setShowType('intro_mobile');
		else setShowType('intro_desktop');
	}

	return (
		<section className="demo">
			<div className="video-container">
				{showType === 'showcase' ? (
					<ReactPlayer
						className="react-player"
						playing
						playsinline
						loop
						controls={false}
						pip={false}
						stopOnUnmount={false}
						muted
						volume={0}
						url={sysShowcaseVideo}
						width="97%"
						height="97%"
					/>
				) : showType === 'intro_desktop' ? (
					<ReactPlayer
						className="react-player desktop-player"
						playing
						controls
						volume={1}
						url={introVideo}
						width="82%"
						height="82%"
					/>
				) : (
					<ReactPlayer
						className="react-player mobile-player"
						playing
						controls
						volume={1}
						url={introVideo}
						width="99%"
						height="99%"
					/>
				)}
			</div>
			{showType === 'showcase' && (
				<button className="play-demo-btn" onClick={handlePlayIntro}>
					FacilityBot 4 Min Overview
					<FontAwesomeIcon name={'fas fa-play'} />
				</button>
			)}
		</section>
	);
}
