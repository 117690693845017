import { useState, useEffect } from 'react';
import { Tabs, Select } from 'antd';

const { TabPane } = Tabs;

import PricingTable from './PricingTable';
import ExpandPricingTable from './ExpandPricingTable';
import { PLANS } from '../../data/pricing_plans';
import geoLocationApi from '../../services/geoLocation';

export default function PricingPlans() {
	const [currency, setCurrency] = useState('USD');
	const [annual, setAnnual] = useState(true);

	const billedBy = annual ? 'annual' : 'monthly';
	const getPlanPrice = (planName, currency, billingCycle) => {
		return PLANS[planName].currency[currency][billingCycle];
	};
	const [plusAccounts, setPlusAccounts] = useState(PLANS['Plus'].maximum_accounts);
	const [plusPrice, setPlusPrice] = useState(getPlanPrice('Plus', currency, billedBy));

	useEffect(() => {
		getCurrency();
	}, []);

	const getCurrency = async () => {
		try {
			const { data } = await geoLocationApi.get('/');
			if (data?.country === 'Singapore' || data?.countryCode === 'SG') handleChangeCurrency('SGD');
		} catch (error) {
			console.error(error);
		}
	};

	const handleTabChange = (activeKey) => {
		if (activeKey === '2') {
			setAnnual(true);
		} else {
			setAnnual(false);
		}

		return;
	};

	const handleChangeCurrency = (val) => {
		setCurrency(val);
	};

	return (
		<section className="pricing-plans">
			<div className="container">
				<div className="pricing-plans__wrapper">
					<h2 className="pricing-plans__heading">Pricing Plans</h2>

					<div className="pricing-plans__content">
						<div className="pricing-currency-select">
							<Select
								size="large"
								value={currency}
								onChange={handleChangeCurrency}
								options={[
									{
										value: 'SGD',
									},
									{
										value: 'USD',
									},
								]}
							/>
						</div>

						<Tabs defaultActiveKey="2" centered={true} tabBarGutter={0} onChange={handleTabChange}>
							<TabPane tab="monthly" key="1">
								<PricingTable
									isAnnual={annual}
									displayCurrency={currency}
									plus={{
										accounts: plusAccounts,
										onAccountsChange: (newVal) => setPlusAccounts(newVal),
										price: plusPrice,
										onPriceChange: (newVal) => setPlusPrice(newVal),
									}}
								/>
							</TabPane>
							<TabPane tab="annual" key="2">
								<PricingTable
									isAnnual={annual}
									displayCurrency={currency}
									plus={{
										accounts: plusAccounts,
										onAccountsChange: (newVal) => setPlusAccounts(newVal),
										price: plusPrice,
										onPriceChange: (newVal) => setPlusPrice(newVal),
									}}
								/>
							</TabPane>
						</Tabs>

						<ExpandPricingTable>
							<PricingTable
								isAnnual={annual}
								displayCurrency={currency}
								plus={{
									accounts: plusAccounts,
									onAccountsChange: (newVal) => setPlusAccounts(newVal),
									price: plusPrice,
									onPriceChange: (newVal) => setPlusPrice(newVal),
								}}
							/>
						</ExpandPricingTable>
					</div>
				</div>
			</div>
		</section>
	);
}
