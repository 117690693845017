import { InputNumber, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import SignupForm from '../Form/signup';

import { PLANS } from '../../data/pricing_plans';
import { DEFAULT_ACCOUNTS } from '../../constants/pricing';

export default function PricingPlan({
	planKey,
	name,
	currency,
	displayCurrency,
	billedBy,
	isAnnual,
	plus,
	handlePlusAccounts,
	maximumAccounts,
	isMobileView = false,
}) {
	const priceNumberClass = `price-number ${displayCurrency === 'INR' ? 'long-number' : ''}`;
	const priceCurrencyClass = `price-currency ${displayCurrency === 'INR' ? 'long-currency' : ''}`;
	const price = planKey === PLANS.Plus.name ? plus.price : currency[displayCurrency][billedBy];

	function updateValue(qry, action, value) {
		const el = document.querySelector(qry);
		const currentValue = parseInt(el.value, 10);

		if (action == 'add') {
			el.value = currentValue + value;
		} else if (action == 'subtract') {
			if (currentValue <= DEFAULT_ACCOUNTS) {
				el.value = DEFAULT_ACCOUNTS;
			} else {
				el.value = currentValue - value;
			}
		}
		handlePlusAccounts(el.value);
	}

	const AccountsNumberController = () => {
		if (isMobileView)
			return (
				<>
					<InputNumber
						size="large"
						id={`mobileAccountNumInput_${billedBy}`}
						defaultValue={plus.accounts}
						onChange={handlePlusAccounts}
						min={DEFAULT_ACCOUNTS}
						style={{
							background: 'white',
							width: '3.1em',
						}}
					/>
					<div className="mobile-accounts-num-controllers">
						<span
							className="mobile-accounts-num-controller"
							onClick={() => updateValue(`#mobileAccountNumInput_${billedBy}`, 'add', 1)}
						>
							<i class="fa fa-caret-up" aria-hidden="true"></i>
						</span>
						<span
							className="mobile-accounts-num-controller"
							onClick={() => updateValue(`#mobileAccountNumInput_${billedBy}`, 'subtract', 1)}
						>
							<i class="fa fa-caret-down" aria-hidden="true"></i>
						</span>
					</div>
				</>
			);
		else return <InputNumber size="large" defaultValue={plus.accounts} onChange={handlePlusAccounts} min={50} />;
	};

	const PlanAccountsNumber = () => {
		if (planKey === PLANS.Plus.name)
			return (
				<div className="plan-item--account-number">
					<Tooltip title="Paid accounts are needed for Admins, Managers and Responders (e.g. technicians, security or cleaning) to sign in to the FacilityBot web portal and mobile app. Requestors (i.e. tenants, building occupants or users who make fault reports and service requests) do not need to sign in and therefore do not require paid accounts">
						<span>Number of accounts </span>
						<InfoCircleOutlined className="" />
					</Tooltip>
					<AccountsNumberController />
				</div>
			);
		else
			return (
				<div className="plan-item--account-number">
					<Tooltip title="Paid accounts are needed for Admins, Managers and Responders (e.g. technicians, security or cleaning) to sign in to the FacilityBot web portal and mobile app. Requestors (i.e. tenants, building occupants or users who make fault reports and service requests) do not need to sign in and therefore do not require paid accounts">
						<span>{maximumAccounts} Accounts </span>
						<InfoCircleOutlined className="" />
					</Tooltip>
				</div>
			);
	};

	return (
		<div className="plan-column plan-item">
			<div className="plan-item--name">{name}</div>
			<div className="plan-item--price">
				<span className={priceNumberClass}>
					{price}
					<span className={priceCurrencyClass}>{displayCurrency}</span>
					<span className="price-interval">Month</span>
				</span>
			</div>
			<div className="plan-item--interval">Billed {isAnnual ? 'Yearly' : 'Monthly'}</div>
			<PlanAccountsNumber />
			<SignupForm btnClass="btn btn--cta-plan" btnText="get started" type={{ name: 'subscription' }} />
		</div>
	);
}
