import { useState } from 'react';
import fcbService from '../services/fcbService';
import { objectToFormData, convertArray, payloadToCreateChecklist } from '../utils/pdf';
import { setCookie, domainName } from '../utils';

import * as dayjs from 'dayjs';
import mailjetService, { interestedLeadsListId } from '../services/mailjetService';
import senderService from '../services/senderService';
import { SENDER_GROUPS } from '../constants/sender';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function useRegisterForm(type, checklistData) {
	let initialInputs = {
		manager_name: '',
		agent_name: '',
		email: '',
		password: '',
		otp: ['', '', '', ''],
	};

	const [inputs, setInputs] = useState(initialInputs);
	const [msg, setMsg] = useState('');
	const [verifying, setVerifying] = useState(false);
	const [verifyMsg, setVerifyMsg] = useState('');
	const [referred_code, setReferredCode] = useState('');
	const [loading, setLoading] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleInputChange = (e) => {
		e.persist();
		if (e.target.name === 'email' && verifying) {
			setVerifying(false);
			setVerifyMsg('');
		}

		setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
	};

	const handleSubmit = async (e) => {
		setVerifyMsg('');
		setVerifying(false);
		setLoading(true);

		try {
			e.preventDefault();

			if (!executeRecaptcha) {
				return;
			}

			const captcha_response = await executeRecaptcha('register_form');

			await fcbService.post('/subscriptions', {
				email: inputs.email,
				manager_name: inputs.manager_name,
				agent_name: inputs.agent_name,
				password: inputs.password,
				referred_code,
				captcha_response,
			});

			setVerifying(true);
			setInputs({ ...inputs, otp: ['', '', '', ''] });
			setLoading(false);
			setMsg('');
		} catch (err) {
			setMsg(err.response.data.message);
			setLoading(false);
		}
	};

	const handleVerify = async (otp) => {
		setLoading(true);
		try {
			const res = await fcbService.post('/subscriptions/verify_subscription_token', {
				code: otp,
			});
			const { access_token } = res.data;
			setCookie('tRefresh', `${access_token}`, 15, domainName());

			// will drop the user information into Interested Leads contact list in mailjet
			await mailjetService.post(`/contactslist/${interestedLeadsListId}/managecontact`, {
				Name: inputs.manager_name,
				Properties: {
					name: inputs.manager_name,
					company: inputs.agent_name,
				},
				Action: 'addnoforce',
				Email: inputs.email,
			});

			await senderService.post('/subscribers', {
				email: inputs.email,
				firstname: inputs.manager_name,
				groups: [SENDER_GROUPS.TRIAL_SIGN_UPS],
			});

			setVerifyMsg('Trial Created Successfully! Redirecting you to the app...');

			if (type.name === 'pdf') {
				const { description, list, title } = checklistData;
				const rowsPayload = convertArray(list);
				const formattedTime = dayjs().format('DD/MM/YYYY HH:mm');
				const futureDate = dayjs().add(1, 'year').format('DD/MM/YYYY');
				const payload = payloadToCreateChecklist(title, description, formattedTime, futureDate, rowsPayload);
				const checklistFormData = objectToFormData(payload);

				const responseToken = await fcbService.get('managers/authentication_token', {
					params: {
						access_token: access_token,
					},
				});

				const authenticateToken = responseToken?.data.authenticate_token;

				const res = await fcbService.post('checklist_settings', checklistFormData, {
					headers: {
						'X-Authentication-Token': authenticateToken,
					},
				});

				const checklistId = await res?.data?.id;
				let redirectLink = `${process.env.NEXT_PUBLIC_FCB_APP_URL}/?access_token=${access_token}&redirect_to=/settings/checklist_settings/${checklistId}?tab=draft`;
				setTimeout(() => {
					// Wait for account creation on fbot
					window.open(redirectLink, '_blank');
				}, 2000);
			} else {
				const chatbotUrl = `${process.env.NEXT_PUBLIC_FCB_APP_URL}/?access_token=${access_token}&redirect_to=`;
				const redirectPaths = {
					trial: '/',
					subscription: '/subscription_plans',
					checklist: '/settings/checklist_settings',
				};

				let redirectLink;
				if (type && type.name) {
					redirectLink = chatbotUrl + redirectPaths[type.name];
				} else {
					redirectLink = chatbotUrl;
				}
				setTimeout(() => {
					// Wait for account creation on fbot
					window.location.assign(redirectLink);
				}, 2000);
			}
		} catch (err) {
			const { message } = err.response.data;
			setVerifyMsg(message);
			setLoading(false);
		}
	};

	const afterClose = () => {
		setMsg('');
		setLoading(false);
		setInputs({ ...initialInputs });
	};

	return {
		inputs,
		verifying,
		msg,
		verifyMsg,
		handleInputChange,
		handleSubmit,
		handleVerify,
		afterClose,
		setReferredCode,
		loading,
	};
}
