const SEO = [
	{
		page: 'Homepage',
		keyword: 'Facilities Management Software',
		title: 'Award-winning CMMS Facilities Management Software',
		desc: "Optimize your operations with FacilityBot's Facilities Management Software. Automate maintenance, digitize checklists, manage assets, manage inventory, reduce costs, and enhance efficiency.",
	},
	{
		page: 'Fault Reporting',
		keyword: 'Facilities Management System',
		title: 'Fault Reporting System | Facilities Management',
		desc: 'FacilityBot has a fault reporting system that allows building occupants to easily report faults through their preferred messaging channel.',
	},
	{
		page: 'Preventive Maintenance',
		keyword: 'Preventive Maintenance',
		title: 'FacilityBot No Download Feature | Preventive Maintenance  ',
		desc: "FacilityBot's preventive maintenance feature allows easier creation of PM checklists, schedule and capture relevant data resulting in increased productivity. ",
	},
	{
		page: 'Asset Management',
		keyword: 'Software for Facilities Management',
		title: 'Software for Facilities Management | Asset Management Feature',
		desc: "FacilityBot is one of the best software for facilities management that helps businesses manage their facility's asset management and maintenance checklist. ",
	},
	{
		page: 'Asset QR Code Scanning',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | Asset QR Code Scanning Feature',
		desc: 'FacilityBot, a facility management software, allows you to scan QR codes to reveal the maintenance history of the assets and easier asset maintenance audits. ',
	},
	{
		page: 'Photo and Video Evidence',
		keyword: 'Property Maintenance ',
		title: 'FacilityBot Chatbot | Property Maintenance Feature',
		desc: 'Capture photo and video evidence of fault rectification and preventive maintenance activities easily using FacilityBot as a property maintenance software.',
	},
	{
		page: 'Statistics, Audit Trails and KPIs',
		keyword: 'Facility Management System',
		title: 'Facility Management System | FacilityBot Statistics Feature',
		desc: 'FacilityBot a facility management system that helps you maintain the statistics of fault reports and preventive maintenance activities including response times.',
	},
	{
		page: 'Facility and Room Booking',
		keyword: 'Building Maintenance Services',
		title: 'Room Booking System | Facilities Management',
		desc: 'FacilityBot can act as your Room Booking software. Our room booking feature allows building occupants to easily book rooms or common facilities through their preferred messaging app.',
	},
	{
		page: 'Surveys',
		keyword: 'Computerized Maintenance Management System',
		title: 'Survey Feature | Computerized Maintenance Management System ',
		desc: 'Choose a computerized maintenance management system that allows your FM team to create surveys using in-built form builder then send to your building users.',
	},
	{
		page: 'Broadcasts',
		keyword: 'Maintenance Management',
		title: 'Maintenance Management | FacilityBot Broadcast Feature ',
		desc: 'Broadcast is a useful maintenance management feature that allows the FM team to seamlessly send messages to building users. Helpful for broadcasting updates.',
	},
	{
		page: 'Automated FAQ Response',
		keyword: 'Facilities Management System',
		title: 'Facilities Management System | FacilityBot Automated FAQ ',
		desc: 'FacilityBot, a facilities management system that allows FM professionals configure responses to frequently asked questions that responds automatically.',
	},
	{
		page: 'Custom Service Requests',
		keyword: 'Maintenance Software',
		title: 'Maintenance Software | FacilityBoy Custom Request Feature',
		desc: "FacilityBot is a maintenance software that can handle almost any type of request. It's a single platform for all your facility management and maintenance needs.",
	},
	{
		page: 'Expenditures',
		keyword: 'Facility Management',
		title: 'Facility Management | FacilityBot Expenditure Feature',
		desc: "FacilityBot is a great facility management software that helps businesses track each report and service's expenditure making inventory and reporting easier.",
	},
	{
		page: 'ROI Calculator',
		keyword: 'Singapore Facilities Management',
		title: 'Singapore Facilities Management | FacilityBot ROI Calculator',
		desc: 'Get immediate return on your investment with Singapore facilities management software that promotes efficiency and accessibility in any messaging platform.',
	},
	{
		page: 'Integrations',
		keyword: 'Singapore Facilities Management',
		title: 'Singapore Facilities Management | Seamless Integrations',
		desc: 'FacilityBot one of the leading Singapore facilities management software that promotes efficiency and accessibility in any messaging platform you prefer.',
	},
	{
		page: 'Referrals',
		keyword: 'Smart Building Management',
		title: 'Smart Building Management | FacilityBot Referral Programme',
		desc: `Join FacilityBot's referral programme. Help businesses enable their building function efficiently and seamlessly through smart building management software.`,
	},
	{
		page: 'Channel Partner',
		keyword: 'Smart Facilities Management',
		title: 'FacilityBot Channel Partner | Smart Facilities Management',
		desc: "See if you're a good fit to become a channel partner of FacilityBot. Let your clients experience the power of Smart Facilities Management automation today!",
	},
	{
		page: 'Multi-Language',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Multi-Language',
		desc: `FacilityBot is a facility management software that understands 109 languages and is dedicated to assist your building users chat in their preferred language.`,
	},
	{
		page: 'Property Portfolio',
		keyword: 'CMMS Software',
		title: 'CMMS Software | FacilityBot Property Portfolio Feature',
		desc: `FacilityBot is a great CMMS software that helps you manage multiple properties and aggregate data from all properties through its property portfolio feature.`,
	},
	{
		page: 'Quick Set Up',
		keyword: 'Facilities Services',
		title: 'Facilities Services Made Easy | FacilityBot Quick Set-Up',
		desc: `FacilityBot, a feature-rich software that is dedicated to streamlining your facilities services. Set up FacilityBot for Fault Reporting within 3 minutes!`,
	},
	{
		page: 'Visitor Management',
		keyword: 'Facility Management System',
		title: 'Visitor Management Software | Facilities Management',
		desc: `Choose a facility management system to help you manage visitors through its visitor management feature where tenants can invite guests to your building.`,
	},
	{
		page: 'Document Management',
		keyword: 'Bot-First CMMS',
		title: 'Bot-First CMMS | FacilityBot Document Management',
		desc: `Improve efficiency of manually tracking faults. Bot-First CMMS like FacilityBot helps facility management teams run infrastructure at maximum performance.`,
	},
	{
		page: 'Procurement',
		keyword: 'Maintenance Management System',
		title: 'Automate Procurement Process | Maintenance Management System',
		desc: `Facilitybot, a maintenance management system that aims to help you automate your procurement process, enhance transparency and gather data. Book a demo today.`,
	},
	{
		page: 'Data Analytics',
		keyword: 'Facilities Management',
		title: 'Facilities Management | Powerful Data Analytics Solution',
		desc: `FacilityBot now implements its new data analytics feature that allows facilities management professionals to put together reports without taking too much time.`,
	},
	{
		page: 'Configurable Workflows',
		keyword: 'Building Maintenance Software',
		title: 'Building Maintenance Software | Configurable Workflows',
		desc: 'FacilityBot, a building maintenance software released a configurable workflows feature that allows you to understand and support each building processes.',
	},
	{
		page: 'Schedule of Rates',
		keyword: 'Facilities System',
		title: 'Facilities Management System | FacilityBot Schedule of Rates',
		desc: 'Easily configure Schedules of Rates. As a Facility Manager, you may have already agreed on a list of prices for each line item with your pre-approved vendors.',
	},
	{
		page: 'HotDesks',
		keyword: 'Facilities Management Software',
		title: 'Hotdesk Software | Hotdesking System',
		desc: 'FacilityBot has a hotdesking system that allows building occupants to book desks through their preferred messaging platform',
	},

	{
		page: 'Checklist Library',
		keyword: 'Facilities Management Software',
		title: 'Facilities Management Software | FacilityBot Checklist Library',
		desc: 'Get your preventive and scheduled maintenance started quickly with FacilityBot’s checklist templates',
	},
	{
		page: 'Commercial Buildings',
		keyword: 'Facilities Management Software',
		title: 'Facilities Management Software | Commercial Buildings',
		desc: 'FacilityBot works great for offices of any type. Delight your office workers with a single messaging-first solution for fault reporting, service requests, visitor management, and facilities booking.',
	},
	{
		page: 'Residential',
		keyword: 'Facilities Management Software',
		title: 'Facilities Management Software | Residential Buildings',
		desc: 'FacilityBot is a hassle-free residential facilities management system. Find out how you can delight residents with an All-in-One System.',
	},
	{
		page: 'Education',
		keyword: 'Facilities Management Software',
		title: 'Facilities Management Software | Educational Buildings',
		desc: 'Find out how FacilityBot can help to make your campus a great place to study in whether it is a sprawling campus with multiple buildings or contained within a single building.',
	},
	{
		page: 'Industrial Buildings',
		keyword: 'Facilities Management Software',
		title: 'Facilities Management Software | Industrial Buildings',
		desc: 'Equipment uptime is crucial in manufacturing plants and industrial buildings. Find out how FacilityBot can help manage your industrial building.',
	},
	{
		page: 'Government',
		keyword: 'Facilities Management Software',
		title: 'Facilities Management Software | Government Buildings',
		desc: 'Facilities management processes in Government buildings should be efficient, thorough, and well-documented. Find out how FacilityBot supports buildings in adhering to best-in-class audit standards.',
	},
	{
		page: 'Hotels',
		keyword: 'Facilities Management Software',
		title: 'Facilities Management Software | Hotel Buildings',
		desc: 'Maintain the highest service standards levels of hotels with FacilityBot. Find out how FacilityBot can help manage your hotels, delighting your guests.',
	},
	{
		page: 'Retail Chains',
		keyword: 'Facilities Management Software',
		title: 'Facilities Management Software | Retail Buildings',
		desc: 'Find out how FacilityBot can help to manage your facilities, procurement, and aggregate data across multiple locations with ease.',
	},
	{
		page: 'FacilityBot Academy',
		keyword: 'Facilities Management Software',
		title: 'FacilityBot Academy | Smart Facilities Management',
		desc: 'Find out how FacilityBot can help to manage your facilities, procurement, and aggregate data across multiple locations with ease.',
	},
	{
		page: 'IOT Sensors Integration',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot IOT Sensors Integration',
		desc: 'Find out how FacilityBot can help to manage your facilities, procurement, and aggregate data across multiple locations with ease.',
	},
	{
		page: 'Meters',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Meters',
		desc: "FacilityBot's Meters feature allows you to create meters of any type including energy, water, and equipment usage meters. Meters can be tagged with location tags, to identify the location of the meter, and linked to assets, to be able to view meter data from the asset perspective.",
	},
	{
		page: 'Parts',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Parts',
		desc: "FacilityBot's Parts feature allows you to easily create new parts with a starting balance and then link the part to an asset.",
	},
	{
		page: 'Attendance',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Attendance',
		desc: "FacilityBot's Attendance feature allows you to easily create new sites at which you would like to track Responder check-ins and check-outs. Simply enter the GPS coordinates of the site and the radius around which check-ins are valid.",
	},
	{
		page: 'Add to Slack',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Slack',
		desc: 'Allow your building users to experience FacilityBot directly through Slack',
	},
	{
		page: 'Custom Dashboard',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Custom Dashboard',
		desc: "FacilityBot's Custom Dashboard feature allows you configure a custom dashboard by selecting from various charts and tables within FacilityBot",
	},
	{
		page: 'Vendor Management',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Vendor Management',
		desc: "FacilityBot's Vendor Management feature allows you to manage all third-party vendor interactions in one system, including procurement, work allocation and invoice processing",
	},
	{
		page: 'Billing',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Billing',
		desc: "FacilityBot's Billing feature allows you to send invoices to your customers",
	},
	{
		page: 'Versus Competitor',
		keyword: 'Facility Management Software',
		title: 'FacilityBot CMMS: Easy to Use Facilities Management Software',
		desc: 'FacilityBot is a cloud-based facilities management software chatbot that offers a seamless user experience with no downloads and no logins needed.',
	},
	{
		page: 'Licenses',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Licenses',
		desc: "FacilityBot's Licenses feature allows you to track licenses in your facility",
	},
	{
		page: 'Budget',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Budget',
		desc: "FacilityBot's Budget feature allows you to keep maintenance expenditures in check",
	},
	{
		page: 'Responder Live Location',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Responder Live Location',
		desc: "FacilityBot's Responder Live Location feature allows you to view the Live Location of all Responders",
	},
	{
		page: 'BMS Integration',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot BMS Integration',
		desc: "FacilityBot's BMS Integration feature allows you to integrate with BMS systems using our proprietary BACNet Gateway",
	},
	{
		page: 'QR Code Fault Reporting',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot QR Code Fault Reporting',
		desc: "FacilityBot's QR Code Fault Reporting allows the creation of unique QR codes for each location or asset. Therefore, when the Requestor scans the QR code, the location tag or asset ID would be pre-populated. Admins can also select the form that should appear when the QR code is scanned. The form could be a fault report form, any custom service request form, or a standardized form such as the toilet feedback form",
	},
	{
		page: 'Sensor Maps',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Sensor Maps',
		desc: "FacilityBot's Sensor Maps allows view the condition of Sensors on a Floor Plan",
	},
	{
		page: 'White Labelling',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot White Labelling',
		desc: 'FacilityBot can replace our branding in specific locations with your branding for clients with more than 200 accounts',
	},
	{
		page: 'Automated Checklist Generator',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Automated Checklist Generator',
		desc: 'FacilityBot can automatically create checklists using ChatGPT technology',
	},
	{
		page: 'Scheduling',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Scheduling',
		desc: "FacilityBot's Schedule can digitize your field service operations by scheduling daily tasks for technicians",
	},
	{
		page: 'Tenant Portal',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Tenant Portal',
		desc: "FacilityBot's Tenant Portal can customize the FacilityBot Sign In Page to become a Tenant Portal.",
	},
	{
		page: 'Customers',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Customers',
		desc: "FacilityBot's Customers can Manage Customers for Billing, Scheduling Tasks and Tracking Customer History. Great for Facilities and Field Service Managers with Multiple Customers.",
	},
	{
		page: 'Contracts',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Contracts',
		desc: "FacilityBot's Contracts Easily track Contracts that you have as part of your Facilities Management operations.",
	},
	{
		page: 'Onboarding',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Onboarding',
		desc: "FacilityBot's Onboarding Team is here to help all our clients get the most with FacilityBot.",
	},
	{
		page: 'Feature Requests',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Feature Requests',
		desc: "FacilityBot's Feature Requests let users provide detailed feedback, suggestions, and feature requests for FacilityBot, as we value user input to continually improve our product.",
	},
	{
		page: 'Payments',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Payments',
		desc: "FacilityBot's Payments Collect Payments from Requestors / Tenants via Credit Card or PayNow ",
	},
	{
		page: 'Field Services Management',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Field Services Management',
		desc: 'FacilityBot has been designed to seamlessly support field services teams',
	},
	{
		page: 'Cleaning',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Cleaning',
		desc: 'FacilityBot Digitize your cleaning operations with one single platform',
	},
	{
		page: 'Landscaping',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Landscaping',
		desc: 'FacilityBot Digitize your Landscaping operations with one single platform',
	},
	{
		page: 'Pest Control',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Pest Control',
		desc: 'FacilityBot Digitize your Pest Control operations with one single platform',
	},
	{
		page: 'Mechanical and Electrical',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Mechanical and Electrical',
		desc: 'FacilityBot Digitize your Mechanical and Electrical operations with one single platform',
	},
	{
		page: 'Medical Equipment',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Medical Equipment',
		desc: 'FacilityBot Digitize your Medical Equipment operations with one single platform',
	},
	{
		page: 'Audio and Visual',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Audio and Visual',
		desc: 'FacilityBot Digitize your Audio and Visual operations with one single platform',
	},
	{
		page: 'FacilityBot Virtual Demo',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Virtual Demo',
		desc: 'FacilityBot Virtual Demo',
	},
	{
		page: 'FacilityBot Permit to Work',
		keyword: 'Facility Management Software',
		title: 'Facility Management Software | FacilityBot Permit to Work',
		desc: 'FacilityBot Permit to Work Digitize your Permit to Work application and approval process',
	},
];

export const pageSEO = (pageName) => {
	const defaultSEO = {
		keyword: 'Facilities Management Software',
		title: 'Award-winning CMMS Facilities Management Software',
		desc: "Optimize your operations with FacilityBot's Facilities Management Software. Automate maintenance, digitize checklists, manage assets, manage inventory, reduce costs, and enhance efficiency.",
	};

	const SEOdata = SEO.find((obj) => obj.page == pageName) || defaultSEO;

	const { keyword, title, desc } = SEOdata;

	return {
		keyword,
		title,
		desc,
	};
};
