export const DEFAULT_ACCOUNTS = 50;
export const DEFAULT_PRICE = 2220;
export const PER_ACCOUNT_PRICE = {
	SGD: {
		monthly: 60,
		annual: 50,
	},
	USD: {
		monthly: 45,
		annual: 37,
	},
	INR: {
		monthly: 3600,
		annual: 3000,
	},
};
export const ACCOUNTS_PER_TB = 10;
export const SENSORS_PER_ACCOUNT = 2;
