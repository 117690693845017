import { useState, useRef } from 'react';
import { Input } from 'antd';

export default function OTPInput({ values, onChange }) {
	const inputsRef = [useRef(), useRef(), useRef(), useRef()];

	const handleChange = (index, event) => {
		let newValues = [...values];
		let value = event.target.value;

		if (value.length === 1) {
			newValues[index] = value;
			if (index < 3) inputsRef[index + 1].current.focus(); // Auto move to next input
		}

		onChange(newValues);
	};

	const handleKeyDown = (index, event) => {
		if (event.key === 'Backspace') {
			let newValues = [...values];
			newValues[index] = '';
			onChange(newValues);

			if (index > 0) inputsRef[index - 1].current.focus(); // Auto move to previous input
		}
	};

	const handlePaste = (event) => {
		event.preventDefault();
		const pasteData = event.clipboardData.getData('text').slice(0, 4).split('');

		let newValues = ['', '', '', ''];
		pasteData.forEach((char, i) => (newValues[i] = char));

		onChange(newValues);
		if (pasteData.length > 0) inputsRef[Math.min(3, pasteData.length - 1)].current.focus();
	};

	return (
		<Input.Group size="large">
			{values.map((val, index) => (
				<Input
					key={index}
					ref={inputsRef[index]}
					value={val}
					maxLength={1}
					onChange={(e) => handleChange(index, e)}
					onKeyDown={(e) => handleKeyDown(index, e)}
					onPaste={handlePaste}
					style={{ width: 50, textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}
				/>
			))}
		</Input.Group>
	);
}
