import { CheckCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import Link from 'next/link';
import { isNull } from '../../utils/helper';

import { ACCOUNTS_PER_TB, SENSORS_PER_ACCOUNT } from '../../constants/pricing';
const PLUS_PLAN_ACCOUNTS_NUMBER_INDEXES = {
	groupIdx: 0,
	featureIdx: 1,
	valueIndex: 3,
};

export default function FeatureGroup({ groupIdx, feature_group_name, link, features, selectedPlanIndex = null, plus }) {
	return (
		<div>
			<div className="plan-row comparison-table feature-group">
				<div className="plan-column feature-group-name">
					<FeatureName link={link} name={feature_group_name} />
				</div>
				{selectedPlanIndex === null && (
					<>
						<div className="plan-column"></div>
						<div className="plan-column"></div>
						<div className="plan-column"></div>
					</>
				)}
			</div>

			{features.map(({ name, value, link }, featureIndex) => (
				<FeatureRow
					key={featureIndex}
					groupIdx={groupIdx}
					featIdx={featureIndex}
					features={features}
					selectedPlanIndex={selectedPlanIndex}
					plus={plus}
					name={name}
					value={value}
					link={link}
				/>
			))}
		</div>
	);
}

const FeatureRow = ({ groupIdx, featIdx, features, selectedPlanIndex = null, plus, name, value, link }) => {
	const borderBottomClass = featIdx < features.length - 1 ? 'border-bottom' : '';
	const planRowClass = isNull(selectedPlanIndex) ? 'plan-row' : '';
	if (!isNull(selectedPlanIndex)) value = [value[selectedPlanIndex]];

	function displayFeatureValue(val, valueIndex) {
		if (val === true) {
			return <CheckCircleTwoTone twoToneColor="#22D69B" />;
		} else if (val === false) {
			return <MinusCircleTwoTone twoToneColor="#FF6377" />;
		} else if (!isNaN(val)) {
			const isAccountsOfPlusPlan =
				groupIdx === PLUS_PLAN_ACCOUNTS_NUMBER_INDEXES.groupIdx &&
				featIdx === PLUS_PLAN_ACCOUNTS_NUMBER_INDEXES.featureIdx &&
				valueIndex === PLUS_PLAN_ACCOUNTS_NUMBER_INDEXES.valueIndex;

			if (isAccountsOfPlusPlan) {
				return plus.accounts;
			}
			return val;
		} else if (typeof val === 'string') {
			const numberOfAccounts = plus.accounts;
			switch (val) {
				case 'limit_accounts':
					return numberOfAccounts;
				case 'custom_storage':
					return `${numberOfAccounts / ACCOUNTS_PER_TB} TB`;
				case 'custom_onboarding':
					return (
						<Link href="/resources/onboarding#enhanced-onboarding" className="blue-link">
							<i className="fas fa-dollar-sign"></i>
						</Link>
					);
				case 'limit_sensors':
					return `Up to ${numberOfAccounts * SENSORS_PER_ACCOUNT}`;
				default:
					return val;
			}
		}
	}

	return (
		<div className={`${planRowClass} comparison-table ${borderBottomClass}`} key={featIdx}>
			<div className="plan-column comparison-header">
				<FeatureName link={link} name={name} />
			</div>
			{value.map((item, valueIndex) => (
				<div className="plan-column" style={{ margin: 'auto' }} key={valueIndex}>
					{displayFeatureValue(item, valueIndex)}
				</div>
			))}
		</div>
	);
};

const FeatureName = ({ link, name }) => {
	if (!link) return name;
	else
		return (
			<Link href={link} rel="noopener noreferrer" target="_blank">
				{name}
			</Link>
		);
};
